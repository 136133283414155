import {
  UPDATE_LOANINFO,
  SET_LOANPROPERTIES,
  SET_ORIGINALLOANINFORMATION,
  UPDATE_ORIGINAL_LOANINFO,
  UPDATE_ORIGINAL_LOAN_LOCKREQUEST,
  SET_USERDATA,
} from "common/constants/ActionConstants";
import Common from "common/services/Common";
import Rights from "common/services/Rights";
import { openGlobalErrorModalAction } from "data/globalErrorModal/actionCreators";
import createAction from "utils/createAction";
import { setOriginalLoanDataAction } from "data/origin/actions";
import { setBuySideAdjustmentsAction } from "data/origin/actionCreators";
import { PATH_LOCK_EXTENSION, PATH_LOCK_SUMMARY } from "routes/constants";
import { closeLoader, openLoader } from "data/screenLoader/actions";
import { setPersonaAccessData } from "data/personaAccess/actionCreators";
import api from "utils/api";
import {
  loanData as mockLoanData,
  loanProperties as mockLoanProperties,
  originalLoanInformation as mockOriginalLoanInf,
} from "common/constants/SearchSampleData";

import Session, {
  CRED,
  ORIGIN_IS_UPDATE,
  IS_BUYSIDE,
  IS_LOCK_EXTENSION,
  IS_SELLSIDE,
  ORIGIN_ID,
  ORIGIN_LOCKID,
  ORIGIN_TRANSACTION_TYPE,
  ORIGIN_SOURCE,
  PAT_TOKEN,
  USER_TYPE,
} from "services/Session";
import ssf from "@elliemae/em-ssf-guest";
import {
  DEBUG_BUYSIDE,
  DATA_SOURCE_BUYSIDE,
} from "common/constants/AppGlobals";
import { setRedirectTabAction } from "../data/landing/actions";

export const APPLICATION_ERROR = "APPLICATION_ERROR";
export const IS_INVALID_TOKEN = "INVALID_ACCESS_TOKEN";
export const IS_INVALID_CREDENTIAL = "INVALID_CREDENTIAL";
export const IS_RECONCILE = "RECONCILE_CREDENTIAL";
export const IS_RESOURCE_NOT_FOUND = "RESOURCE_NOT_FOUND";

const checkForLoanAmount = ({
  lienPosition,
  firstMortgageAmount,
  secondMortgageAmount,
}) => {
  let hasLoanAmount = false;
  if (lienPosition === 1) {
    hasLoanAmount = !!firstMortgageAmount;
  }
  if (lienPosition === 2) {
    hasLoanAmount = !!secondMortgageAmount;
  }
  return hasLoanAmount;
};

export const parseLoanData = (loanData) => {
  let { secondMortgageAmount } = loanData.loanInformation;
  if (typeof secondMortgageAmount === "number") {
    secondMortgageAmount = Math.trunc(secondMortgageAmount);
  }
  const result = {
    ...loanData,
    loanInformation: {
      ...loanData.loanInformation,
      secondMortgageAmount,
    },
  };
  return result;
};

export const updateLoanInfoSuccessAction = (data) => {
  const action = createAction(UPDATE_LOANINFO);
  const payload = parseLoanData(data);
  return action(payload);
};

export const setOriginalLoanInformation = createAction(
  SET_ORIGINALLOANINFORMATION
);
export const setUserData = createAction(SET_USERDATA);
export const setLoanProperties = createAction(SET_LOANPROPERTIES);
const updateOriginalLoanInfoAction = createAction(UPDATE_ORIGINAL_LOANINFO);
const updateOriginalLoanLockRequest = createAction(
  UPDATE_ORIGINAL_LOAN_LOCKREQUEST
);
const showNoPermissionsError = (dispatch) =>
  dispatch(
    openGlobalErrorModalAction({
      title: "You do not have permission to access this page.",
      errorMessage: "Please contact your administrator to request changes.",
      onCloseCallback: Common.closeApplication,
      confirmLabel: "Close ICE PPE",
      showClose: false,
    })
  );

export const getInitData = async (
  dispatch,
  isRefresh,
  setMoveNext,
  setIsError
) => {
  dispatch(openLoader("Loading"));
  Session.set(USER_TYPE, { userType: "LO" });
  ssf.connect();
  ssf.ready();
  await Common.sourceApplicationName();
  const data = await getOrigin(isRefresh, dispatch, setMoveNext, setIsError);
  return data;
};

export const dispatchUserData = async (dispatch) => {
  const userData = await Common.getUserData();
  if (userData && !Object.prototype.hasOwnProperty.call(userData, "code")) {
    dispatch(setUserData(userData));
  }
};

export const setMockInitData = async (dispatch) => {
  const prvUser = Session.get(USER_TYPE);
  Session.set(USER_TYPE, { userType: !prvUser ? "StandAlone" : prvUser });
  if (DEBUG_BUYSIDE) {
    Session.set(ORIGIN_TRANSACTION_TYPE, DATA_SOURCE_BUYSIDE);
  }
  dispatch(updateLoanInfoSuccessAction(mockLoanData));
  dispatch(setLoanProperties(mockLoanProperties));
  dispatch(setOriginalLoanInformation(mockOriginalLoanInf));
};

export const dispatchOriginData = (
  dispatch,
  {
    originalLoanInformation,
    originalLoanInformation: { lockRequests },
    loanProperties,
    loanData,
    lockRequestInformation,
    buySideAdjustments,
  },
  setMoveNext
) => {
  const { credentials } = originalLoanInformation;
  dispatch(updateLoanInfoSuccessAction(loanData));
  dispatch(setLoanProperties(loanProperties));
  dispatch(setOriginalLoanInformation(originalLoanInformation));
  dispatch(updateOriginalLoanInfoAction(loanData));
  dispatch(setOriginalLoanDataAction(originalLoanInformation));
  dispatch(updateOriginalLoanLockRequest(lockRequestInformation));
  Session.set(CRED, credentials);
  if (Session.get(IS_LOCK_EXTENSION)) {
    dispatch(setRedirectTabAction(PATH_LOCK_EXTENSION));
  } else if (Session.get(IS_BUYSIDE)) {
    if (!Rights.accessBuyside) {
      showNoPermissionsError(dispatch);
      return;
    }
    if (buySideAdjustments) {
      dispatch(setBuySideAdjustmentsAction(buySideAdjustments));
    }
    Session.set(ORIGIN_IS_UPDATE, lockRequests || []);
    dispatch(setRedirectTabAction(PATH_LOCK_SUMMARY));
  } else if (Session.get(IS_SELLSIDE)) {
    if (!Rights.accessSellside) {
      showNoPermissionsError(dispatch);
      return;
    }
  }
  setMoveNext(true);
};

const validateOrigin = async (originData) => {
  const { loanData, code, summary } = originData;
  const { loanInformation } = loanData || {};
  const { lienPosition } = loanInformation || {};

  const hasLoanAmount = checkForLoanAmount(loanInformation || {});
  const loanAmountBlank =
    code === APPLICATION_ERROR &&
    summary ===
      "Loan Amount is Blank. Please enter Loan Amount and retry pricing";
  const notTokenCredResource = [
    IS_INVALID_TOKEN,
    IS_INVALID_CREDENTIAL,
    IS_RESOURCE_NOT_FOUND,
  ].includes(code);
  const validation = {
    notTokenCredResource,
    loanAmountBlank,
    hasLoanAmount,
    lienPosition,
    originData,
    code,
  };
  if (notTokenCredResource) {
    const reconcile = await Common.reconcileInvalidCredentials();
    if (
      reconcile.code === IS_INVALID_TOKEN ||
      reconcile.code === IS_INVALID_CREDENTIAL ||
      reconcile.code === IS_RESOURCE_NOT_FOUND ||
      reconcile.token
    ) {
      validation[IS_RECONCILE] = IS_RECONCILE;
    }
    if (!reconcile) {
      validation.isRedirectToError = true;
    }
  }
  return validation;
};

const getTransactionData = async (isRefresh) => {
  const transactionObj = await ssf.getObject("transaction");
  const data = await (isRefresh
    ? transactionObj.refreshOrigin()
    : transactionObj.getOrigin());
  return data;
};

const setInfoSession = async (
  { source, partnerAccessToken, id, settings },
  dispatch
) => {
  let newSource = source;
  // TODO: temporary code. This should be replace when reviselock story is going to implement
  if (source) {
    newSource = source.replace("reviselock", "getbuysidepricing");
  }
  if (DEBUG_BUYSIDE) {
    newSource = DATA_SOURCE_BUYSIDE;
  }
  Session.set({
    [PAT_TOKEN]: partnerAccessToken,
    [ORIGIN_SOURCE]: newSource,
    [ORIGIN_TRANSACTION_TYPE]: newSource,
    [ORIGIN_LOCKID]: newSource,
    [ORIGIN_ID]: id,
  });
  dispatch(setPersonaAccessData(settings));
};

const openDocumentViewer = async (transactionId) => {
  const response = await api.getTransactionDetails(transactionId);
  if (response && response.resources) {
    try {
      const applicationObject = await ssf.getObject("application");
      const modalStatus = await applicationObject.openModal({
        target: {
          entityId: response.resources.id,
          entityType: "urn:elli:skydrive",
        },
      });
      if (modalStatus === "success") {
        await Common.closeApplication();
      }
    } catch (error) {
      // console.log({ error });
    }
  }
};

export const getOrigin = async (
  isRefresh,
  dispatch,
  setIsError,
  setMoveNext
) => {
  const redirectToError = () => {
    setMoveNext(true);
    setIsError(true);
  };
  const data = await getTransactionData(isRefresh);
  setInfoSession(data, dispatch);
  const { transactionId } = data;
  if (transactionId) {
    await openDocumentViewer(transactionId);
  }
  if (!transactionId) {
    try {
      const originData = await Common.getOrigin();
      const result = validateOrigin(originData);
      return result;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch(closeLoader());
      dispatch(
        openGlobalErrorModalAction({
          errorMessage:
            "Something went wrong getting Origin. Please contact the administrator.",
          onCloseCallback: () => {
            redirectToError();
          },
        })
      );
      redirectToError();
    }
  }
  return null;
};
