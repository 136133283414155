import { isEmpty } from "utils/shared";
import Session, { ORIGIN_SOURCE } from "services/Session";
import { useLoanDataSelector } from "data/useSelectors";

export const getTPOActionAccessibility = (
  allowedActions,
  requestActionType
) => {
  const defaultValue = "ENABLED";
  let filterItem = [];
  if (allowedActions) {
    filterItem = allowedActions.filter(
      (item) => item.action === requestActionType
    );
  }
  return filterItem ? filterItem[0]?.accessibility : defaultValue;
};

export const isRequestLockButtonVisible = (applicationName, allowedActions) => {
  let isVisible = true;
  if (
    applicationName &&
    applicationName === "TPO" &&
    getTPOActionAccessibility(allowedActions, "LockRequest") === "HIDDEN"
  ) {
    isVisible = false;
  }
  return isVisible;
};

export const isSaveToLoanButtonVisible = (
  applicationName,
  allowedActions,
  tpo,
  clientSettingsData
) => {
  const hideLockRequest = clientSettingsData?.hideLockRequest;
  if (hideLockRequest) {
    return false;
  }
  const icePpeFloatDisabled =
    clientSettingsData && !clientSettingsData.icePpeFloat;
  if (applicationName && applicationName === "TPO") {
    const isHidden =
      getTPOActionAccessibility(allowedActions, "FloatLock") === "HIDDEN";
    if (
      isHidden ||
      tpo?.underwritingDelegated ||
      icePpeFloatDisabled ||
      hideLockRequest
    )
      return false;
  }
  return icePpeFloatDisabled;
};

export const isRequestLockButtonDisabled = (
  rateStale,
  loanData,
  applicationName,
  allowedActions,
  isDisableRequestLockButton,
  shutDownAllowLockRequestValidation
) => {
  const { requestLockStatus, requestAction } = loanData;
  const isExpiredPricing = rateStale > 0; // 1 or 999
  const isLoanOfficer = Session.get(ORIGIN_SOURCE) !== "secondarylock";
  const isAllowLockRequest = shutDownAllowLockRequestValidation();
  if (
    applicationName &&
    applicationName === "TPO" &&
    getTPOActionAccessibility(allowedActions, "LockRequest") === "DISABLED"
  ) {
    return true;
  }
  const isDisabled =
    (requestLockStatus === 0 && requestAction === 2) ||
    (requestLockStatus === 1 && requestAction === 2) ||
    (requestLockStatus === 3 && requestAction === 1) ||
    (requestLockStatus === 4 && requestAction === 1) ||
    (isLoanOfficer && isExpiredPricing) ||
    isDisableRequestLockButton ||
    isAllowLockRequest;

  return isDisabled;
};

export const isFloatButtonVisible = (clientSettingsData) => {
  return clientSettingsData && clientSettingsData.icePpeFloat;
};

export const isFloatButtonDisabled = () => {
  const { requestLockStatus, rateAction } = useLoanDataSelector();

  const isDisabled =
    (requestLockStatus === 2 ||
      requestLockStatus === 3 ||
      requestLockStatus === 4) &&
    rateAction == null;

  return isDisabled;
};

export const isSaveToLoanButtonDisabled = (
  loanData,
  applicationName,
  allowedActions
) => {
  if (applicationName && applicationName === "TPO") {
    const isDisabled =
      getTPOActionAccessibility(allowedActions, "FloatLock") === "DISABLED";
    if (isDisabled) return true;
    const { requestLockStatus, requestAction } = loanData;
    return requestAction === 2 && [3, 4].includes(requestLockStatus);
  }
  return false;
};

export const getRequestLockActionType = (loanData) => {
  let type = null;
  if (!isEmpty(loanData)) {
    const { requestLockStatus, requestAction } = loanData;
    if (requestLockStatus === 0 || requestLockStatus === 1) {
      if (requestAction === 1) type = "NewLock";
    }
    if (requestLockStatus === 2) {
      if (requestAction === 1) type = "ActiveNewLock";
      if (requestAction === 2) type = "ActiveLockUpdate";
    }
    if (requestLockStatus === 3) {
      if (requestAction === 2) type = "ExpiredReLock";
    }
    if (requestLockStatus === 4) {
      if (requestAction === 2) type = "CancelledReLock";
    }
  }
  return type;
};

export const getEPCActionType = (requestLockActionType, autolocked) => {
  const { buyside, sellSideBestPrice, sellSideSameProduct } = autolocked;
  let EPCActionType;
  if (
    requestLockActionType === "NewLock" ||
    requestLockActionType === "ActiveNewLock"
  ) {
    EPCActionType = "LOCK";
  } else {
    EPCActionType = "RELOCK";
  }
  if (buyside || sellSideBestPrice || sellSideSameProduct) {
    EPCActionType = "LOCK_CONFIRM";
  }
  return EPCActionType;
};

export const checkLockRequested = (loanData, lockRequests) => {
  const { requestAction, requestLockStatus } = loanData;
  const hasLockRequest = lockRequests?.some(
    (request) => request.requestedStatus === "Requested"
  );
  return hasLockRequest && requestAction === 1 && requestLockStatus === 1;
};
