import moment from "moment";
import {
  minmaxValidation,
  minmaxValidation1,
  numberFieldSanitizer,
} from "./helpers";

const loanInformationValidators = {
  lienPos: {
    fieldName: "lienPos",
    required: true,
    message: "Required Field",
  },
  loanUsage: {
    fieldName: "loanUsage",
    allowedFalseyValues: [0],
    required: true,
    message: "Required Field",
    equal: { value: 99, message: "Loan Purpose is invalid." },
  },
  commitmentTypes: {
    fieldName: "commitmentTypes",
    required: ({ dependencies, required }) => {
      if (dependencies.value === 4) return true;
      return required;
    },
    message: "Required Field",
  },
  deliveryTypes: {
    fieldName: "deliveryTypes",
    required: ({ dependencies, required }) => {
      if (dependencies.value === 4) return true;
      return required;
    },
    message: "Required Field",
  },
  propertyValue: {
    fieldName: "propertyValue",
    required: ({ required }) => required,
    sanitizer: numberFieldSanitizer,
    message: "Required Field",
    minmax: minmaxValidation,
  },
  firstMortgageAmount: {
    fieldName: "firstMortgageAmount",
    required: ({ required }) => required,
    sanitizer: numberFieldSanitizer,
    message: "Required Field",
    minmax: minmaxValidation1,
  },
  financedAmount: {
    fieldName: "financedAmount",
    allowedFalseyValues: [0],
    required: ({ required }) => required,
    sanitizer: numberFieldSanitizer,
    message: "Required Field",
    minmax: minmaxValidation1,
  },
  secondMortgageAmount: {
    fieldName: "secondMortgageAmount",
    required: ({ dependencies, required }) => {
      const { lienPosition } = dependencies;
      if (lienPosition === 2) return true;
      return required;
    },
    sanitizer: numberFieldSanitizer,
    message: "Required Field",
    minmax: minmaxValidation,
    customValidator: (data, { dependencies }) => {
      const { secondMortgageAmount } = data;
      const { otherPayment, lienPosition } = dependencies;
      const result = { hasError: false, message: null };
      if (
        ["", null].includes(secondMortgageAmount) &&
        (otherPayment || lienPosition)
      ) {
        if (otherPayment && lienPosition === 1) {
          result.hasError = true;
          result.message = "Required Field";
          return result;
        }
        if (lienPosition === 2) {
          result.hasError = true;
          result.message = "Required Field";
          return result;
        }
      }
      if (secondMortgageAmount <= 0 && lienPosition === 2) {
        result.hasError = true;
        result.message = "Value must be greater than 0";
      } else if (lienPosition === 1) {
        if (
          !["", null].includes(secondMortgageAmount) &&
          secondMortgageAmount <= 0
        ) {
          result.hasError = true;
          result.message = "Value must be greater than 0";
        }
      }
      return result;
    },
  },
  otherPayment: {
    fieldName: "otherPayment",
    required: ({ dependencies, required }) => {
      const { lienPosition } = dependencies;
      if (lienPosition === 2) return true;
      return required;
    },
    sanitizer: numberFieldSanitizer,
    message: "Required Field",
    customValidator: (data, { dependencies }) => {
      const { otherPayment } = data;
      const { secondMortgageAmount, lienPosition } = dependencies;
      const result = { hasError: false, message: null };
      if (
        ["", null].includes(otherPayment) &&
        (secondMortgageAmount || lienPosition)
      ) {
        if (secondMortgageAmount && lienPosition === 1) {
          result.hasError = true;
          result.message = "Required Field";
          return result;
        }
        if (lienPosition === 2) {
          result.hasError = true;
          result.message = "Required Field";
          return result;
        }
      }
      if (otherPayment <= 0 && lienPosition === 2) {
        result.hasError = true;
        result.message = "Value must be greater than 0";
      } else if (lienPosition === 1) {
        if (!["", null].includes(otherPayment) && otherPayment <= 0) {
          result.hasError = true;
          result.message = "Value must be greater than 0";
        }
      }
      return result;
    },
  },
  totSubClosedEndMort: {
    fieldName: "totSubClosedEndMort",
    allowedFalseyValues: [0],
    sanitizer: numberFieldSanitizer,
    message: "Required Field",
    minmax: minmaxValidation,
  },
  loanAmountMaxLine: {
    fieldName: "loanAmountMaxLine",
    required: ({ required, dependencies }) => {
      const { lienPosition, isHELOC } = dependencies;
      if (lienPosition === 2 && !isHELOC) return !required;
      return required;
    },
    sanitizer: numberFieldSanitizer,
    message: "Required Field",
    customValidator: (data, { dependencies }) => {
      const { loanAmountMaxLine } = data;
      const { isHELOC, lienPosition } = dependencies;
      const result = { hasError: false, message: null };
      if (!isHELOC && lienPosition === 2 && loanAmountMaxLine < 0) {
        result.hasError = true;
        result.message = "Value must be greater than 0";
      } else if (!(!isHELOC && lienPosition === 2) && loanAmountMaxLine <= 0) {
        if (["", null].includes(loanAmountMaxLine)) {
          result.hasError = true;
          result.message = "Required Field";
          return result;
        }
        if (loanAmountMaxLine <= 0) {
          result.hasError = true;
          result.message = "Value must be greater than 0";
        }
      }
      if (loanAmountMaxLine && loanAmountMaxLine > 999999999) {
        result.hasError = true;
        result.message = "Must be lower than 999,999,999";
      }
      return result;
    },
  },
  cashOut: {
    allowedFalseyValues: [0],
    fieldName: "cashOut",
    required: true,
    message: "Required Field",
  },
  effectiveDate: {
    fieldName: "effectiveDate",
    required: ({ required }) => required,
    customValidator: (data, dependencies) => {
      const { effectiveDate } = data;
      const dataRetentionDays = dependencies.dependencies;
      const parsedEffectiveDate = moment(effectiveDate);
      const xDaysBeforeToday = moment().subtract(dataRetentionDays, "days");
      let result = { hasError: false, message: null };
      if (parsedEffectiveDate.isBefore(xDaysBeforeToday)) {
        result = {
          hasError: true,
          message: `Effective Date must not be prior to ${xDaysBeforeToday.format(
            "MM/DD/YYYY"
          )}.`,
        };
      }
      return result;
    },
  },
};

export default loanInformationValidators;
